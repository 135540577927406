<template>
  <div>
    <hero></hero>
    <privacy-policy></privacy-policy>
    <tour-5></tour-5>
    <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>